export const API_STATUS = {
  loading: 'loading',
  success: 'success',
  error: 'error',
};

// 2mb
export const MAX_FILE_SIZE = 4 * 1024 * 1024;

// field status
export const EVENT_STATUS = {
  ALL: 0,
  DRAFT: 1,
  ON_REVIEW: 2,
  PUBLISH: 3,
  REJECT: 4,
};

export const FILTER_EVENTS_STATUS = {
  ALL: 'ALL',
  FUTURE: 'FUTURE',
  PAST: 'PAST',
  DRAFT: 'DRAFT',
  ON_REVIEW: 'ON_REVIEW',
  PUBLISH: 'PUBLISH',
  REJECT: 'REJECT',
};

export const eventType = [
  { title: 'eventType.meeting', value: 'meeting' },
  { title: 'eventType.exhibition', value: 'exhibition' },
  { title: 'eventType.training', value: 'training' },
  { title: 'eventType.masterClass', value: 'masterClass' },
  { title: 'eventType.course', value: 'course' },
  { title: 'eventType.lecture', value: 'lecture' },
  { title: 'eventType.standUp', value: 'standUp' },
  { title: 'eventType.concert', value: 'concert' },
  { title: 'eventType.boardGame', value: 'boardGame' },
  { title: 'eventType.sport', value: 'sport' },
  { title: 'eventType.webinar', value: 'webinar' },
  { title: 'eventType.degustation', value: 'degustation' },
  { title: 'eventType.music', value: 'music' },
  { title: 'eventType.tour', value: 'tour' },
  { title: 'eventType.online', value: 'online' },
  { title: 'eventType.standUp', value: 'standUp' },
  { title: 'eventType.theaters', value: 'theaters' },
  { title: 'eventType.networking', value: 'networking' },
  { title: 'eventType.film', value: 'film' },
  { title: 'eventType.festival', value: 'festival' },
  { title: 'eventType.kids', value: 'kids' },
];

export const eventStatus = [
  { title: 'eventStatus.draft', value: 1 },
  { title: 'eventStatus.onReview', value: 2 },
  { title: 'eventStatus.published', value: 3 },
  { title: 'eventStatus.rejected', value: 4 },
];

export const price = [
  { title: 'price.free', value: false },
  { title: 'price.paid', value: true },
];

export const accountType = [
  { title: 'accountType.admin', value: '0' },
  { title: 'accountType.standard', value: '1' },
  { title: 'accountType.company', value: '2' },
];

export const city = [
  { title: 'city.dnipro', value: 'dnipro' },
  { title: 'city.kiev', value: 'kyiv' },
  { title: 'city.lviv', value: 'lviv' },
];

export const country = [
  { title: 'country.ua', value: 'ua' },
];

export const POPULAR_UA = 1;
export const POPULAR_TAG = 2;
export const SALES_TAG = 3;
export const SUPER_POPULAR_TAG = 4;

export const tags = [
  { title: 'tags.ua', value: POPULAR_UA },
  { title: 'tags.popular', value: POPULAR_TAG },
  { title: 'tags.sales', value: SALES_TAG },
  { title: 'tags.superPopular', value: SUPER_POPULAR_TAG },
];

export const priorities = [
  { title: 'priorities.normal', value: 1 },
  { title: 'priorities.height', value: 2 },
  { title: 'priorities.super', value: 3 },
];

const NAVCOLO_ORG_ID = '6383e71f121e7765cb39fab3';
const EVENTS_DNIPRO = '65477f1dd95afce5adb6044b';
const EVENTS_KIEV = '6589c95abb56e7e35097ffe6';
const EVENTS_LVIV = '65e36e3a8f909aa73c9a1472';

export const OUR_ORGANIZATIONS = [NAVCOLO_ORG_ID, EVENTS_DNIPRO, EVENTS_KIEV, EVENTS_LVIV];
